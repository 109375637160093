<template>
    <b-container>
        <b-row class="mt-3">
            <b-col>
                <h5>選擇日期</h5>
            </b-col>
        </b-row>
        <b-button-group>
            <label class="pt-1 pr-2">日結</label>
            <b-form-checkbox v-model="isChecked" switch size="lg" @change="endDate = null" />
            <label class="pt-1">月結</label>
        </b-button-group>
        <b-form-group label="開始日期">
            <b-form-datepicker v-model="startDate" />
        </b-form-group>
        <b-form-group v-if="isChecked" label="結束日期">
            <b-form-datepicker v-model="endDate" />
        </b-form-group>

        <n-list value="orders" text="訂單" @updateShop="getShop" :shopOptions="shopOptions" :sortBy="sortBy" :sortDesc="sortDesc" :fields="fields" :items="orderByShop" :selectShop="true" :disabled="true" />
    </b-container>
</template>

<script>
// import moment from 'moment'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
    data() {
        return {
            shop: null,
            order: null,
            sortBy: 'uniNum',
            sortDesc: true,
            isChecked: false,
            startDate: null,
            endDate: null,
            fields: [
                {
                    key: 'uniNum',
                    label: '單號',
                    sortable: true,
                },

                {
                    key: 'source',
                    label: '來源',
                    sortable: true,
                },
                {
                    key: 'method',
                    label: '方式',
                    sortable: true,
                    class: 'd-none d-sm-table-cell',
                    formatter: value => {
                        switch (value) {
                            case 'PICKUP':
                                return '自取'
                            case 'DELIVERY':
                                return '外送'
                        }
                    },
                },
                {
                    key: 'grandTotal',
                    label: '總計',
                    formatter: value => {
                        return `$${value}`
                    },
                    sortable: true,
                },
                {
                    key: 'payment.method',
                    label: '付款方法',
                    sortable: true,
                },
                // {
                //     key:'completedAt',
                //     label: '付款時間',
                //     formatter: value => {
                //         return moment.unix(Number(value)).format('YYYY-MM-DD HH:mm:ss')
                //     }
                // },
                {
                    key: 'products',
                    label: '商品',
                    formatter: value => {
                        for (let i = 0; i < value.length; i++) {
                            return value[i].name
                        }
                    },
                    sortable: true,
                },
                {
                    key: 'status',
                    label: '狀態',
                    sortable: true,
                    formatter: value => {
                        switch (value) {
                            case 'PENDING':
                                return '待接單'
                            case 'RECEIVED':
                                return '已接單'
                            case 'PENDING_PAYMENT':
                                return '待付款'
                            case 'PACKED':
                                return '待取餐'
                            case 'COMPLETED':
                                return '已完成'
                            case 'VOID':
                                return '已取消'
                        }
                    },
                },
            ],
        }
    },

    created() {
        this.$store.dispatch('bindCollectionByMerchant', { col: 'orders', merchantId: localStorage.merchantId })
        this.$store.dispatch('bindCollectionByMerchant', { col: 'shops', merchantId: localStorage.merchantId })
    },

    watch: {
        async startDate(newStartDate) {
            this.$store.state.startDate = newStartDate
            await this.$store.dispatch('bindOrders')
        },
        async endDate(newEndDate) {
            this.$store.state.endDate = newEndDate
            await this.$store.dispatch('bindOrders')
        },
    },

    computed: {
        ...mapState({
            orders: state => state.orders,
            shops: state => state.shops,
        }),

        shopOptions() {
            if (!this.shops) return []
            let ret = this.shops.map(shop => {
                return {
                    text: shop.name,
                    value: {
                        id: shop.id,
                        name: shop.name,
                    },
                }
            })
            ret.unshift({ text: '全部分店', value: null })
            return ret
        },

        orderByShop() {
            let res = []
            if (!this.shop) {
                _.cloneDeep(this.orders).forEach(order => {
                    return res.push(order)
                })
            } else {
                _.cloneDeep(this.orders).forEach(order => {
                    if (order.shop.id == this.shop.id) return res.push(order)
                })
            }
            return res
        },
    },

    methods: {
        getShop(shop) {
            this.shop = shop
        },

        dayTime(val) {
            this.moment(val).format('YYYY-MM-DD HH:mm:ss')
        },
    },
}
</script>
